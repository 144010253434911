var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"w-full sticky top-0 z-10"},[_c('div',{staticClass:"w-full bg-gray-700"},[_c('div',{staticClass:"container mx-auto py-2 lg:p-8 lg:py-3"},[_c('div',{staticClass:"w-full flex justify-between items-center p-2 text-white"},[_c('div',{staticClass:"text-white p-5 w-1/4"},[_c('WaveLogo',{staticClass:"cursor-pointer h-16 md:h-12 lg:h-16"})],1),_vm._m(0),_c('div',{staticClass:"w-1/4 text-right"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(),"dddd Do MMMM"))+" ")])])])])])]),_c('main',{staticClass:"container mx-auto py-2 lg:p-8 lg:py-3"},[_vm._m(1),(_vm.remainingSurfers.length > 0)?_c('div',{staticClass:"border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-4"},[_c('div',{staticClass:"flex flex-col items-center justify-around m-8"},[_vm._m(2),_c('div',{staticClass:"w-full m-6 px-4"},_vm._l((_vm.remainingSurfers),function(surfer,index){return _c('div',{key:'surfer-' + index,staticClass:"md:w-10/12 lg:w-9/12 mx-auto"},[_c('div',{staticClass:"m-4 p-2 rounded bg-cool-gray-500 text-white flex justify-around items-center"},[_c('h3',{staticClass:"text-lg capitalize"},[_vm._v(" "+_vm._s(surfer.firstName)+" "+_vm._s(surfer.lastName)+" ")]),(
									surfer.bookings &&
										surfer.bookings.length > 1
								)?_c('div',{staticClass:"w-2/5 px-8"},[_c('div',{staticClass:"text-white"},[_c('h4',{staticClass:"text-base border-b inline"},[_vm._v(" Multiple Bookings ")]),_vm._l((_vm.collateBookings(
											surfer.bookings
										)),function(booking,bookingIndex){return _c('div',{key:'surfer-booking-' + bookingIndex},[_c('h5',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(booking.performanceDatetime, "dddd, MMMM Do YYYY" ))+" ")])])})],2)]):_c('div',{staticClass:"w-2/5 px-8"},[_c('h4',{staticClass:"text-base border-b inline"},[_vm._v(" "+_vm._s(surfer.surfName)+" ")]),_c('h5',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(_vm._f("moment")(surfer.performanceDatetime,"dddd, MMMM Do YYYY"))+" ")])])])])}),0),_c('div',{staticClass:"md:w-10/12 lg:w-9/12 px-8"},[(this.getLocation())?_c('p',{staticClass:"inline-block"},[_vm._v(" You all booked using the same email address "),_c('strong',[_vm._v(_vm._s(_vm.reservation.primaryEmailAddress))]),_vm._v(" so you can either forward your waiver email over to them, or get them to visit "),_c('a',{staticClass:"font-bold inline",attrs:{"href":this.getLocation() +
									'/surfers/sign-waivers?emailAddress=' +
									this.$route.query.emailAddress.toLowerCase()}},[_vm._v(" "+_vm._s(this.getLocation() + "/surfers/sign-waivers/")+" ")]),_vm._v(" and enter "),_c('strong',[_vm._v(_vm._s(_vm.reservation.primaryEmailAddress))]),_vm._v(" as the email address. Thanks! ")]):_vm._e()])])]):_vm._e(),_vm._m(3)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden md:block"},[_c('h1',{staticClass:"text-2xl"},[_vm._v("Sign Waiver")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-4"},[_c('div',{staticClass:"relative p-6 flex-auto text-center"},[_c('h2',{staticClass:"mb-2 text-xl font-bold"},[_vm._v(" Thank you! ")]),_c('p',{staticClass:"mb-2 text-lg"},[_vm._v(" Thank you for signing a waiver. "),_c('br'),_vm._v(" You shouldn\"t need to sign a waiver again for 60 days, but you may be asked if you book for a different type of surf session. "),_c('br'),_vm._v(" There is nothing more that you need to do! We look forward to seeing you soon. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-10/12 lg:w-9/12 px-8"},[_c('h2',{staticClass:"text-xl font-bold"},[_vm._v(" Waivers still to sign ")]),_c('p',{staticClass:"text-lg"},[_vm._v(" The following people still need to complete a waiver and watch the safety video: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden rounded-lg shadow-lg relative flex flex-col w-full bg-white mb-4"},[_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":"/thanks-header.jpg"}})])
}]

export { render, staticRenderFns }