<template>
	<!-- Start Template Root Div -->
	<div>
		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5 w-1/4">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div class="hidden md:block">
							<h1 class="text-2xl">Sign Waiver</h1>
						</div>
						<div class="w-1/4 text-right">
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<!--content-->
			<div
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-4"
			>
				<!--body-->
				<div class="relative p-6 flex-auto text-center">
					<h2 class="mb-2 text-xl font-bold">
						Thank you!
					</h2>
					<p class="mb-2 text-lg">
						Thank you for signing a waiver. <br />
						You shouldn&quot;t need to sign a waiver again for 60
						days, but you may be asked if you book for a different
						type of surf session. <br />
						There is nothing more that you need to do! We look
						forward to seeing you soon.
					</p>
				</div>
			</div>

			<!-- Multiple Adults -->
			<div
				v-if="remainingSurfers.length > 0"
				class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mb-4"
			>
				<!--body-->
				<div class="flex flex-col items-center justify-around m-8">
					<!-- - Info Field - -->
					<div class="md:w-10/12 lg:w-9/12 px-8">
						<h2 class="text-xl font-bold">
							Waivers still to sign
						</h2>
						<p class="text-lg">
							The following people still need to complete a waiver
							and watch the safety video:
						</p>
					</div>

					<!-- - Surfer - -->
					<div class="w-full m-6 px-4">
						<div
							v-for="(surfer, index) in remainingSurfers"
							:key="'surfer-' + index"
							class="md:w-10/12 lg:w-9/12 mx-auto"
						>
							<div
								class="m-4 p-2 rounded bg-cool-gray-500 text-white flex justify-around items-center"
							>
								<!-- - Name Text - -->
								<h3 class="text-lg capitalize">
									{{ surfer.firstName }} {{ surfer.lastName }}
								</h3>

								<!-- - Event Details - -->
								<div
									v-if="
										surfer.bookings &&
											surfer.bookings.length > 1
									"
									class="w-2/5 px-8"
								>
									<div class="text-white">
										<h4 class="text-base border-b inline">
											Multiple Bookings
										</h4>
										<div
											v-for="(booking,
											bookingIndex) in collateBookings(
												surfer.bookings
											)"
											:key="
												'surfer-booking-' + bookingIndex
											"
										>
											<h5 class="text-sm">
												{{
													booking.performanceDatetime
														| moment(
															"dddd, MMMM Do YYYY"
														)
												}}
											</h5>
										</div>
									</div>
								</div>
								<div v-else class="w-2/5 px-8">
									<h4 class="text-base border-b inline">
										{{ surfer.surfName }}
									</h4>
									<h5 class="text-sm">
										{{
											surfer.performanceDatetime
												| moment("dddd, MMMM Do YYYY")
										}}
									</h5>
								</div>
							</div>
						</div>
					</div>

					<!-- - URL Field - -->
					<div class="md:w-10/12 lg:w-9/12 px-8">
						<p v-if="this.getLocation()" class="inline-block">
							You all booked using the same email address
							<strong>{{
								reservation.primaryEmailAddress
							}}</strong>
							so you can either forward your waiver email over to
							them, or get them to visit
							<a
								class="font-bold inline"
								:href="
									this.getLocation() +
										'/surfers/sign-waivers?emailAddress=' +
										this.$route.query.emailAddress.toLowerCase()
								"
							>
								{{
									this.getLocation() +
										"/surfers/sign-waivers/"
								}}
							</a>
							and enter
							<strong>{{
								reservation.primaryEmailAddress
							}}</strong>
							as the email address. Thanks!
						</p>
					</div>
				</div>
			</div>

			<!-- - Image - -->
			<div
				class="overflow-hidden rounded-lg shadow-lg relative flex flex-col w-full bg-white mb-4"
			>
				<img
					class="w-full h-full object-cover"
					src="/thanks-header.jpg"
				/>
			</div>
		</main>
	</div>
</template>

<script>
	import WaveLogo from "@/components/WaveLogo.vue";
	import { mapActions } from "vuex";

	export default {
		components: {
			WaveLogo
		},
		data() {
			return {
				validEmail: false,
				emailAddress: "",
				waivers: [],
				reservation: undefined,
				remainingSurfers: []
			};
		},
		created() {
			this.checkData();
		},
		mounted() {
			// Show Forward Screen
			if (this.$route.query.emailForward) this.showForwarding = true;
		},
		methods: {
			// Vuex Mapped Actions from reservations Namespace:
			...mapActions({
				getReservation: "reservations/fetchReservation"
			}),
			getLocation() {
				if (window && window.location) return window.location.origin;
				return;
			},
			collateBookings(bookings) {
				const collatedBookings = [];
				const performanceDatetimes = [];

				bookings.map(booking => {
					const bookingDatetime = booking.performanceDatetime.slice(
						0,
						booking.performanceDatetime.indexOf("T")
					);
					if (!performanceDatetimes.includes(bookingDatetime)) {
						performanceDatetimes.push(bookingDatetime);
						collatedBookings.push(booking);
					}
				});

				return collatedBookings;
			},
			getRemainingSurfers() {
				if (
					!this.reservation ||
					!this.reservation.waiversAdults ||
					!(this.reservation.waiversAdults.length > 1)
				)
					return [];
				return this.reservation.waiversAdults.filter(
					waiver =>
						waiver.hasSeenVideo != true ||
						waiver.hasSignedWaiver != true
				);
			},
			getLastSignedLowest() {
				if (
					!this.reservation ||
					!this.reservation.waiversAdults ||
					!(this.reservation.waiversAdults.length > 1)
				)
					return [];

				// Get Lowest Last Signed Date
				var waiverLastSigned = new Date();
				this.reservation.waiversAdults.forEach(waiver => {
					if (
						waiver.account.waiverLastSigned &&
						new Date(waiver.account.waiverLastSigned) <
							waiverLastSigned
					)
						waiverLastSigned = new Date(
							waiver.account.waiverLastSigned
						);
				});

				// Return String about for remaining days
				return (
					60 -
					this.$moment().diff(this.$moment(waiverLastSigned), "days")
				);
			},
			async checkData() {
				// Setup Plugin & Params
				await this.setupData();

				// Re-append Route & Redirect if missing query params
				if (!this.validEmail) {
					this.$router.push({
						name: "SurferWaiver",
						query: this.$route.query
					});
					return;
				}

				// Call After Setup
				this.receivedData();
			},
			async setupData() {
				// Call Plugin
				if (this.$route.query.emailAddress) {
					// Parse Email Address
					this.emailAddress = this.$route.query.emailAddress
						? this.$route.query.emailAddress
								.replace("..", "@")
								.toLowerCase()
						: "";

					// Get Waivers
					this.reservation = await this.getReservation(
						this.emailAddress
					);
					this.waivers = this.reservation.waivers;

					// Check Email Addresses
					if (
						this.emailAddress ==
						this.reservation.primaryEmailAddress
					)
						this.validEmail = true;
				}
			},
			receivedData() {
				// Get Remaining Surfers
				this.remainingSurfers = this.getRemainingSurfers();
			}
		}
	};
</script>
